import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { alertService as service, userService } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    title: '',
    desc: '',
    user: { id: -1, name: '' },
    users: [],
}

export default function FormAlert() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'users',
            service: userService.comboWithAll,
            multiple: true,
            col: 6,
        },
        {
            type: 'input',
            name: 'title',
            col: 6,
        },
        {
            type: 'textarea',
            name: 'desc',
            col: 6,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['title'] = element.title ? element.title : ''
        objectedit['desc'] = element.desc ? element.desc : ''

        if (element['user'] == null || element['user']['id'] == null) {
            element['user'] = {
                id: -1,
                name: 'Todos',
                email: 'todos@todos.com',
            }
        }

        objectedit['users'] = []
        objectedit['users'][0] = element['user']

        return objectedit
    }

    const isValid = function (object) {
        debugger
        if (!object['title']) {
            return t('alerts.error.title')
        }
        if (!object['desc']) {
            return t('alerts.error.desc')
        }

        if (!object['users'] || object['users'].length <= 0) {
            return t('alerts.error.user')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'alerts'}
                urledit={'/alerts/edit/'}
                urlCancel={'/alerts'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
